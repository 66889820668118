import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {persistenceStates} from '@computerrock/formation-core';
import {useTranslate} from '@computerrock/formation-i18n';
import {apsBudgetTypes} from '@ace-de/eua-entity-types';
import {ButtonPrimary, ContentBlock, ContentItem, DateField, Form, Panel, useStyles, HighlightCircle, LoaderSpinner, NoResultsBlock} from '@ace-de/ui-components';
import {Icon, InteractiveIcon, warningIcon, calendarIcon, editIcon, infoAlertIcon} from '@ace-de/ui-components/icons';
import * as budgetActionTypes from './budgetsActionTypes';
import checkIfTariffPeriodValid from './checkIfTariffPeriodValid';
import checkTimePeriodOverlap from './checkTimePeriodOverlap';
import AidServicesTable from './ui-elements/AidServicesTable';
import ServicesTable from './ui-elements/ServicesTable';

const BudgetsScreen = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {history, timePeriods, confirmBudgetPeriodUpdate, initiateBudgetsUpdate, budgetsPersistenceState} = props;
    const queryParams = new URLSearchParams(history.location.search);
    const currentPeriod = timePeriods.find(timePeriod => timePeriod.id === parseInt(queryParams.get('budgetPeriodId'), 10));
    const otherPeriods = timePeriods.filter(timePeriod => (
        timePeriod.id !== currentPeriod?.id
    ));
    const [budgetPeriodFormData, setBudgetPeriodFormData] = useState();
    const isEditingValidFromDisabled = moment(queryParams.get('validFrom')).isSameOrBefore(moment(), 'day');
    const isEditingValidUntilDisabled = moment(queryParams.get('validUntil')).isBefore(moment(), 'day');
    const [isPeriodDateUpdateDisabled, setIsPeriodDateUpdateDisabled] = useState(true);
    const [isPeriodTariffValid, setIsPeriodTariffValid] = useState(true);

    const handleOnFormChange = formValues => {
        setBudgetPeriodFormData(formValues);
        const hasOverlap = checkTimePeriodOverlap(
            formValues.validFrom,
            formValues.validUntil,
            otherPeriods,
            isEditingValidFromDisabled,
        );

        const isPeriodTariffValid = !checkIfTariffPeriodValid(formValues.validFrom, formValues.validUntil);

        if (hasOverlap || !isPeriodTariffValid) {
            setIsPeriodTariffValid(false);
            setIsPeriodDateUpdateDisabled(true);
            return;
        }

        setIsPeriodTariffValid(true);
        setIsPeriodDateUpdateDisabled(false);
    };

    const handleOnSubmit = () => {
        if (Object.keys(budgetPeriodFormData).length === 0) {
            return;
        }
        const budgetTimePeriodData = {
            ...(budgetPeriodFormData?.validFrom && !isEditingValidFromDisabled
                ? {validFrom: moment(budgetPeriodFormData.validFrom).format('YYYY-MM-DD')}
                : {}
            ),
            ...(budgetPeriodFormData?.validFrom && !isEditingValidUntilDisabled
                ? {validUntil: moment(budgetPeriodFormData.validUntil).format('YYYY-MM-DD')}
                : {}),
            prices: [],
        };
        confirmBudgetPeriodUpdate({budgetTimePeriodId: currentPeriod?.id, budgetTimePeriodData});
    };

    return (
        <div>
            <ContentBlock className={cx('global!ace-u-margin--top-8')}>
                {budgetsPersistenceState === persistenceStates.PENDING && (
                    <div
                        className={cx([
                            'global!ace-u-width--full',
                            'global!ace-u-flex',
                            'global!ace-u-flex--align-center',
                            'global!ace-u-flex--justify-content-center',
                        ])}
                    >
                        <div>
                            <HighlightCircle
                                className={cx([
                                    'ace-c-highlight-circle--medium',
                                    'ace-c-highlight-circle--primary',
                                    'global!ace-u-margin--top-64',
                                ])}
                            >
                                <LoaderSpinner />
                            </HighlightCircle>
                            <p
                                className={cx([
                                    'global!ace-u-margin--top-16',
                                    'global!ace-u-margin--bottom-64',
                                    'global!ace-u-typography--variant-h4',
                                ])}
                            >
                                {translateScreen('loader_message.loading')}
                            </p>
                        </div>
                    </div>
                )}
                {((!currentPeriod && budgetsPersistenceState === persistenceStates.READY)
                        || budgetsPersistenceState === persistenceStates.FAILED)
                    && (
                        <ContentItem>
                            <NoResultsBlock
                                colorVariant={budgetsPersistenceState === persistenceStates.FAILED
                                    ? 'negative'
                                    : 'primary'
                                }
                                icon={(
                                    <Icon
                                        className={cx('ace-c-icon--xxl', {
                                            'global!ace-c-icon--color-highlight': budgetsPersistenceState !== persistenceStates.FAILED,
                                            'global!ace-c-icon--color-warning': budgetsPersistenceState === persistenceStates.FAILED,
                                        })}
                                        icon={infoAlertIcon}
                                    />
                                )}
                                message={budgetsPersistenceState === persistenceStates.FAILED
                                    ? translateScreen('results_error.message')
                                    : translateScreen('no_results.message')}
                                description={budgetsPersistenceState === persistenceStates.FAILED
                                    ? translateScreen('results_error.description')
                                    : translateScreen('no_results.description')}
                            />
                        </ContentItem>
                    )}
                {!!currentPeriod && budgetsPersistenceState === persistenceStates.READY && (
                    <Fragment>
                        <ContentItem className={cx('ace-c-content-item--span-9')}>
                            {Object.keys(apsBudgetTypes).map(budgetType => (
                                <Panel key={budgetType}>
                                    <div
                                        className={cx([
                                            'global!ace-u-margin--bottom-24',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-space-between',
                                            'global!ace-u-flex--align-stretch',
                                        ])}
                                    >
                                        <h3 className={cx('global!ace-u-typography--variant-h3')}>
                                            {translateScreen(`panel_header.${budgetType.toLowerCase()}`)}
                                        </h3>
                                        <InteractiveIcon
                                            icon={editIcon}
                                            onClick={() => initiateBudgetsUpdate({budgetType})}
                                        />
                                    </div>
                                    {budgetType === apsBudgetTypes.SERVICE && <ServicesTable />}
                                    {budgetType === apsBudgetTypes.AID && <AidServicesTable />}
                                </Panel>
                            ))}
                        </ContentItem>
                        <ContentItem className={cx('ace-c-content-item--span-3')}>
                            <div
                                className={cx([
                                    'global!ace-u-flex',
                                    'global!ace-u-flex--direction-column',
                                    'global!ace-u-flex--align-center',
                                ])}
                            >
                                <Form name="budgetPeriodForm" onChange={handleOnFormChange} onSubmit={handleOnSubmit}>
                                    <DateField
                                        name="validFrom"
                                        className={cx(['global!ace-u-margin--bottom-8', 'global!ace-u-width--full'])}
                                        label={translateScreen('date_field_label.valid_from')}
                                        value={currentPeriod?.validFrom || ''}
                                        icon={calendarIcon}
                                        isDisabled={isEditingValidFromDisabled}
                                    />
                                    <DateField
                                        name="validUntil"
                                        className={cx(['global!ace-u-margin--bottom-8', 'global!ace-u-width--full'])}
                                        label={translateScreen('date_field_label.valid_until')}
                                        value={currentPeriod?.validUntil || ''}
                                        icon={calendarIcon}
                                        isDisabled={isEditingValidUntilDisabled}
                                    />
                                    {!isPeriodTariffValid && (
                                        <p
                                            className={cx([
                                                'global!ace-u-flex',
                                                'global!ace-u-margin--bottom-16',
                                                'global!ace-u-typography--color-warning',
                                                'global!ace-u-typography--variant-caption',
                                                'global!ace-u-flex--align-center',
                                            ])}
                                        >
                                            <Icon
                                                icon={warningIcon}
                                                className={cx([
                                                    'global!ace-u-margin--right-8',
                                                    'ace-c-icon--xl',
                                                    'ace-c-icon--color-warning',
                                                ])}
                                            />
                                            <span>{translateScreen('error_message.mixed_tariff_periods')}</span>
                                        </p>
                                    )}
                                    <div
                                        className={cx([
                                            'global!ace-u-full-width',
                                            'global!ace-u-flex',
                                            'global!ace-u-flex--justify-flex-end',
                                        ])}
                                    >
                                        <ButtonPrimary
                                            name="confirmChanges"
                                            type="submit"
                                            isDisabled={isPeriodDateUpdateDisabled || !budgetPeriodFormData}
                                        >
                                            {translateScreen('button_label.save')}
                                        </ButtonPrimary>
                                    </div>
                                </Form>
                            </div>
                        </ContentItem>
                    </Fragment>
                )}
            </ContentBlock>
        </div>
    );
};

BudgetsScreen.propTypes = {
    history: PropTypes.object.isRequired,
    confirmBudgetPeriodUpdate: PropTypes.func.isRequired,
    initiateBudgetsUpdate: PropTypes.func.isRequired,
    timePeriods: PropTypes.array,
    budgetsPersistenceState: PropTypes.string.isRequired,
};

BudgetsScreen.defaultProps = {
    timePeriods: [],
};

const mapStateToProps = state => {
    return {
        timePeriods: state.budgets.timePeriods,
        budgetsPersistenceState: state.budgets.budgetsPersistenceState,
    };
};

const mapDispatchToProps = dispatch => ({
    confirmBudgetPeriodUpdate: payload => dispatch({
        type: budgetActionTypes.INITIATE_UPDATE_TIME_PERIOD_FLOW,
        payload,
    }),
    initiateBudgetsUpdate: payload => dispatch({
        type: budgetActionTypes.INITIATE_BUDGETS_UPDATE_FLOW,
        payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetsScreen);
