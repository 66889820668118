const errorTypes = {
    USER_CREATION_FAILED: 'USER_CREATION_FAILED',
    USER_EDIT_FAILED: 'USER_EDIT_FAILED',
    SERVICE_PROVIDER_UPDATE_FAILED: 'SERVICE_PROVIDER_UPDATE_FAILED',
    COMMISSIONER_CREATION_FAILED: 'COMMISSIONER_CREATION_FAILED',
    COMMISSIONER_EDIT_FAILED: 'COMMISSIONER_EDIT_FAILED',
    INA_RULES_EDIT_FAILED: 'INA_RULES_EDIT_FAILED',
    ASSISTANCE_FEES_EDIT_FAILED: 'ASSISTANCE_FEES_EDIT_FAILED',
    DELETE_CP_CONTACT_DATA_FAILED: 'DELETE_CP_CONTACT_DATA_FAILED',
    CREATE_CP_CONTACT_DATA_FAILED: 'CREATE_CP_CONTACT_DATA_FAILED',
    EDIT_CP_CONTACT_FAILED: 'EDIT_CP_CONTACT_FAILED',
    CP_SERVICES_EDIT_FAILED: 'CP_SERVICES_EDIT_FAILED',
    CP_BASIC_INFO_UPDATE_FAILED: 'CP_BASIC_INFO_UPDATE_FAILED',
    CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED: 'CP_ASSIGNMENT_CHANNELS_UPDATE_FAILED',
    CP_STATUS_UPDATE_FAILED: 'CP_STATUS_UPDATE_FAILED',
    ESTIMATION_EDIT_FAILED: 'ESTIMATION_EDIT_FAILED',
    STANDBY_TIMES_UPDATE_FAILED: 'STANDBY_TIMES_UPDATE_FAILED',
    BUDGET_TIME_PERIOD_UPDATE_FAILED: 'BUDGET_TIME_PERIOD_UPDATE_FAILED',
    BUDGET_TIME_PERIOD_CREATE_FAILED: 'BUDGET_TIME_PERIOD_CREATE_FAILED',
    UPDATE_BUDGETS_BY_TIME_UPDATE_FAILED: 'UPDATE_BUDGETS_BY_TIME_UPDATE_FAILED',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export default errorTypes;
