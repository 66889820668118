import {produce} from 'immer';
import moment from 'moment';
import {persistenceStates} from '@computerrock/formation-core';
import {apsBudgetTypes, apsAidServiceTypes, AidServiceBudget, getBudgetTypeClass, apsServiceTypes, ServiceBudget} from '@ace-de/eua-entity-types';
import * as budgetActionTypes from './budgetsActionTypes';

const initialState = {
    timePeriods: [],
    latestTimePeriod: null,
    budgetsPersistenceState: persistenceStates.READY,
    budgets: [],
    serviceBudgets: [],
    aidBudgets: [],
    timePeriodBudgetsPersistenceState: persistenceStates.PENDING,
};

const updateBudgetArrayByType = (prices, budgetDTO, uniqueField, budgetType) => {
    if (!prices) return;
    const BudgetClass = getBudgetTypeClass(budgetType);

    return prices.map(budget => {
        return budget[uniqueField] === budgetDTO[uniqueField]
            ? new BudgetClass().fromDTO(budgetDTO)
            : budget;
    });
};

const budgetReducer = produce((draft, action) => {
    switch (action.type) {
        case budgetActionTypes.STORE_TIME_PERIODS: {
            const {timePeriods} = action.payload;
            if (Array.isArray(timePeriods) && timePeriods.length) {
                const sortedPeriods = timePeriods.sort((a, b) => moment(a.validUntil).diff(moment(b.validUntil)));
                draft.timePeriods = sortedPeriods;
                draft.latestTimePeriod = sortedPeriods[sortedPeriods.length - 1];
            }
            break;
        }

        case budgetActionTypes.STORE_UPDATED_PERIOD: {
            const {budgetTimePeriodDTO} = action.payload;
            draft.timePeriods = draft.timePeriods.map(timePeriod => {
                if (timePeriod.id === budgetTimePeriodDTO.id) {
                    return budgetTimePeriodDTO;
                }
                return timePeriod;
            });
            break;
        }

        case budgetActionTypes.SET_BUDGETS_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.budgetsPersistenceState = persistenceState;
            break;
        }

        case budgetActionTypes.STORE_BUDGETS_BY_TIME: {
            const {budgetTimePeriodDTO} = action.payload;
            const {budgets} = budgetTimePeriodDTO;

            draft.budgets = budgets;

            let serviceBudgets = {};

            let aidBudgets = Object.values(apsAidServiceTypes).map(aidServiceType => new AidServiceBudget({
                serviceType: apsBudgetTypes.AID,
                type: aidServiceType,
            }));

            budgets?.forEach(budgetDTO => {
                if (Object.keys(apsServiceTypes).includes(budgetDTO.serviceType)) {
                    serviceBudgets = {
                        ...serviceBudgets,
                        [budgetDTO.tariffGroup]: {
                            ...serviceBudgets[budgetDTO.tariffGroup],
                            [budgetDTO.serviceType]: {
                                ...(serviceBudgets[budgetDTO.tariffGroup]
                                    ? serviceBudgets[budgetDTO.tariffGroup][budgetDTO.serviceType]
                                    : {}),
                                [budgetDTO.memberStatus]: new ServiceBudget().fromDTO(budgetDTO),
                            },
                        },
                    };
                }
                switch (budgetDTO.serviceType) {
                    case apsBudgetTypes.AID:
                        aidBudgets = updateBudgetArrayByType(
                            aidBudgets,
                            budgetDTO,
                            'type',
                            apsBudgetTypes.AID,
                        );
                        break;
                    default:
                }
            });
            draft.aidBudgets = aidBudgets;
            draft.serviceBudgets = serviceBudgets;

            break;
        }

        case budgetActionTypes.SET_TIME_PERIOD_BUDGETS_PERSISTENCE_STATE: {
            const {persistenceState} = action.payload;
            draft.timePeriodBudgetsPersistenceState = persistenceState;
            break;
        }

        default: // no-op
    }
}, initialState);

export default budgetReducer;
