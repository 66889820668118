import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {ButtonPrimary, Modal, useStyles} from '@ace-de/ui-components';
import {InteractiveIcon, Icon, warningIcon, closeIcon} from '@ace-de/ui-components/icons';
import {apsBudgetTypes} from '@ace-de/eua-entity-types';
import * as budgetActionTypes from '../budgetsActionTypes';
import AidServicesTable from '../ui-elements/AidServicesTable';
import ServicesTable from '../ui-elements/ServicesTable';

const EditBudgetsModal = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateModal = createTranslateShorthand('edit_budgets_modal');
    const {location, hasBackdrop, declineUpdateBudgets, confirmUpdateBudgets} = props;
    // todo set initial values once budgets are available
    const [formData, setFormData] = useState();
    const modalType = location?.query?.type;

    const isFormFieldEmpty = formData && !!Object.keys(formData).find(key => {
        return Object.values(formData[key]).some(value => value === '');
    });

    return (
        <Modal
            title={translateModal(`modal_title.${modalType}`)}
            action={(
                <InteractiveIcon
                    icon={closeIcon}
                    onClick={declineUpdateBudgets}
                />
            )}
            hasBackdrop={hasBackdrop}
            className={cx('global!ace-u-modal-content-size--m')}
            contentClassName={cx('ace-c-modal__content--scrollable')}
        >
            <div className={cx('global!ace-u-margin--top-16')}>
                {modalType.toUpperCase() === apsBudgetTypes.SERVICE && (
                    <ServicesTable isEdit={true} onChange={setFormData} />
                )}
                {modalType.toUpperCase() === apsBudgetTypes.AID && (
                    <AidServicesTable isEdit={true} onChange={setFormData} />
                )}
            </div>
            {isFormFieldEmpty && (
                <p
                    className={cx([
                        'global!ace-u-flex',
                        'global!ace-u-margin--top-16',
                        'global!ace-u-typography--color-warning',
                        'global!ace-u-typography--variant-caption',
                        'global!ace-u-flex--align-center',
                        'global!ace-u-margin--left-16',
                    ])}
                >
                    <Icon
                        icon={warningIcon}
                        className={cx([
                            'global!ace-u-margin--right-8',
                            'ace-c-icon--s',
                            'ace-c-icon--color-warning',
                        ])}
                    />
                    <span>
                        {translateModal('error_message.field_empty')}
                    </span>
                </p>
            )}
            <div
                className={cx([
                    'global!ace-u-width--full',
                    'global!ace-u-flex',
                    'global!ace-u-flex--justify-flex-end',
                ])}
            >
                <ButtonPrimary
                    name="confirmUpdateBudgets"
                    onClick={() => confirmUpdateBudgets({budgetData: formData})}
                    isDisabled={!formData || isFormFieldEmpty}
                >
                    {translateModal('button_label.save')}
                </ButtonPrimary>
            </div>
        </Modal>
    );
};

EditBudgetsModal.propTypes = {
    location: PropTypes.object.isRequired,
    declineUpdateBudgets: PropTypes.func.isRequired,
    confirmUpdateBudgets: PropTypes.func.isRequired,
    hasBackdrop: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
    declineUpdateBudgets: () => dispatch({
        type: budgetActionTypes.DECLINE_UPDATE_BUDGETS,
    }),
    confirmUpdateBudgets: payload => dispatch({
        type: budgetActionTypes.CONFIRM_UPDATE_BUDGETS,
        payload,
    }),
});

export default connect(null, mapDispatchToProps)(EditBudgetsModal);
