import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {connect} from 'react-redux';
import {useTranslate} from '@computerrock/formation-i18n';
import {NumberInput, Table, TableBody, TableCell, TableHead, TableRow, useStyles} from '@ace-de/ui-components';
import {apsAidServiceTypes} from '@ace-de/eua-entity-types';
import memberStatuses from '../memberStatuses';
import config from '../../config';

const AidServicesTable = props => {
    const {cx} = useStyles();
    const {createTranslateShorthand} = useTranslate();
    const translateScreen = createTranslateShorthand('budgets_screen');
    const {isEdit, onChange, timePeriods, history} = props;
    const queryParams = new URLSearchParams(history?.location?.search);
    // todo check current period once time periods are available
    const currentPeriod = timePeriods.find(timePeriod => timePeriod.id === parseInt(queryParams.get('budgetPeriodId'), 10));

    const handleOnChange = (service, status, value) => {
        if (typeof onChange !== 'function') return;

        onChange(prevState => ({
            ...prevState,
            [service.toLowerCase()]: {
                ...(prevState && prevState[service.toLowerCase()]),
                [status.toLowerCase()]: value,
            },
        }));
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={3}>
                        {translateScreen('table_header.service')}
                    </TableCell>
                    {memberStatuses.map(status => (
                        <TableCell
                            key={status}
                            colSpan={2}
                        >
                            {translateScreen(`table_header.member_status_${status.toLowerCase()}`)}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.values(apsAidServiceTypes).filter(aidServiceType => (
                    aidServiceType !== apsAidServiceTypes.ELEMENTAL
                    || moment(currentPeriod?.validFrom).isSameOrAfter(config.BUDGETS_DATE_INTERSECTION)
                )).map(service => (
                    <TableRow key={service}>
                        <TableCell colSpan={3}>
                            {translateScreen(`table_cell.service_${service.toLowerCase()}`)}
                        </TableCell>
                        {!isEdit
                            ? <TableCell colSpan={8} />
                            : memberStatuses.map(status => (
                                <TableCell colSpan={2} key={`${service}-${status}`}>
                                    <NumberInput
                                        name={`${service}-${status}`}
                                        className={cx([
                                            'global!ace-u-width--full',
                                            'ace-c-number-input--small',
                                        ])}
                                        onChange={value => handleOnChange(service, status, value)}
                                    />
                                </TableCell>
                            ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

AidServicesTable.propTypes = {
    isEdit: PropTypes.bool,
    onChange: PropTypes.func,
    timePeriods: PropTypes.array,
    history: PropTypes.object.isRequired,
};

AidServicesTable.defaultProps = {
    isEdit: false,
    onChange: null,
    timePeriods: [],
};

const mapStateToProps = state => ({
    timePeriods: state.budgets.timePeriods,
});

export default connect(mapStateToProps)(AidServicesTable);
