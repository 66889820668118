import {take, select, fork, put} from 'redux-saga/effects';
import {openModal, closeModal} from '@computerrock/formation-router/sagas';
import * as budgetActionTypes from '../budgetsActionTypes';
import modalIds from '../../modalIds';
import fetchRequest from '../../application/sagas/fetchRequest';
import errorTypes from '../../application/errorTypes';
import * as applicationActionTypes from '../../application/applicationActionTypes';

const initiateCreateTimePeriodFlow = function* initiateCreateTimePeriodFlow() {
    const {serviceManager} = yield select(state => state.application);
    const pricingManagementService = serviceManager.loadService('pricingManagementService');

    while (true) {
        yield take(budgetActionTypes.INITIATE_CREATE_TIME_PERIOD_FLOW);

        yield* openModal(modalIds.CREATE_TIME_PERIOD_MODAL);

        const chosenModalOption = yield take([
            budgetActionTypes.CONFIRM_CREATE_TIME_PERIOD,
            budgetActionTypes.DECLINE_CREATE_TIME_PERIOD,
        ]);

        if (chosenModalOption
            && chosenModalOption.type === budgetActionTypes.CONFIRM_CREATE_TIME_PERIOD
        ) {
            const {payload} = chosenModalOption;
            const {searchQueryParams} = payload;

            yield fork(
                fetchRequest,
                budgetActionTypes.CREATE_TIME_PERIOD_REQUEST,
                pricingManagementService.createBudgetTimePeriod, {
                    searchQueryParams,
                },
            );
            const responseAction = yield take([
                budgetActionTypes.CREATE_TIME_PERIOD_REQUEST_SUCCEEDED,
                budgetActionTypes.CREATE_TIME_PERIOD_REQUEST_FAILED,
            ]);

            /* if (!responseAction.error) {
                const {response} = responseAction.payload;
                const {budgetTimePeriodDTO} = response;

                // todo store budgets
            } */
            if (responseAction.error) {
                yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);
                yield put({
                    type: applicationActionTypes.INITIATE_ERROR_MESSAGE_FLOW,
                    payload: {errorType: errorTypes.BUDGET_TIME_PERIOD_CREATE_FAILED},
                });
                continue;
            }
        }

        yield* closeModal(modalIds.CREATE_TIME_PERIOD_MODAL);
    }
};

export default initiateCreateTimePeriodFlow;
